import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sum"
export default class extends Controller {
  static targets = ["term", "total"];

  termTargetConnected(term) {
    if (this.hasTotalTarget) {
      term.addEventListener("change", () => {
        this.totalTarget.innerText = this.#sumTerms();
      });
    };
  };

  #sumTerms() {
    const sum = this.termTargets.reduce((total, term) => {
      const rawValue     = term.innerText;
      const numericValue = parseFloat(rawValue.replace(/\./g, "").replace(",", ".")) || 0;

      return total + numericValue;
    }, 0);

    return sum.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };
};
