import { Controller } from "@hotwired/stimulus";
import { setCookie, getCookie, hasCookie, eraseCookie } from "../services/cookies";

// Connects to data-controller="camera"
export default class extends Controller {
  static targets = ["display", "deviceContainer", "input"];
  static values  = { device: String };

  DEVICE_COOKIE     = "camera_device";
  RESOLUTION_COOKIE = "camera_resolution";

  videoStream;
  canvas;
  snapshot;

  connect() {
    if (this.element.querySelector("img")) this.snapshot = this.element.querySelector("img").parentElement;
  };

  async deviceContainerTargetConnected(target) {
    await this.#showAvailableDevices(target);

    this.deviceValue = getCookie(this.DEVICE_COOKIE) || undefined;
  };

  deviceValueChanged(value, previousValue) {
    const valueElement         = document.getElementById(value);
    const previousValueElement = document.getElementById(previousValue);

    if (valueElement) valueElement.classList.add("active");

    if (previousValueElement && (valueElement != previousValueElement)) {
      previousValueElement.classList.remove("active");
      eraseCookie(this.RESOLUTION_COOKIE);
    };

    if (value) setCookie(this.DEVICE_COOKIE, value);

    if (this.videoStream instanceof MediaStream && this.videoStream.active) {
      this.stop();
      this.play();
    };
  };

  async play() {
    if (this.snapshot) this.eraseSnapshot();

    this.#setVideoStream();
  };

  stop() {
    if (this.snapshot) this.eraseSnapshot();

    this.videoStream.getTracks().forEach((track) => track.stop());
  };

  disconnect() {
    this.stop();
  };

  async capture() {
    let canvas = document.createElement("canvas");

    canvas.width = this.displayTarget.videoWidth;
    canvas.height = this.displayTarget.videoHeight;
    canvas.getContext("2d").drawImage(this.displayTarget, 0, 0);

    this.canvas = canvas;
    await this.#upload();
    this.#setSnapshot();
  };

  eraseSnapshot() {
    this.snapshot.remove();
    this.snapshot = undefined;
    this.#clearInput();
    this.#toggleDisplay();
  };

  async #upload() {
    const blob = await new Promise(resolve => this.canvas.toBlob(resolve));
    const file = new File([blob], "photo");
    const dataTransfer = new DataTransfer();

    dataTransfer.items.add(file);
    this.inputTarget.files = dataTransfer.files;
  };

  #clearInput() {
    this.inputTarget.files = new DataTransfer().files;
  };

  #setSnapshot() {
    const div = document.createElement("div");
    div.classList.add("position-relative", "h-100", "mx-auto", "d-block");
    div.style.aspectRatio = "3 / 4;"

    const img = document.createElement("img");
    img.src = this.canvas.toDataURL("image/png");
    img.classList.add("w-100", "h-100");

    div.appendChild(img);
    div.appendChild(this.#eraseButton());

    this.#toggleDisplay();
    this.stop();
    this.displayTarget.after(div);
    this.snapshot = div;
  };

  #eraseButton() {
    const button = document.createElement("button");
    button.type = "button";
    button.classList.add("btn", "btn-sm", "btn-danger", "p-0", "m-2", "position-absolute", "top-0", "end-0");
    button.dataset["action"] = "click->camera#eraseSnapshot";

    const icon = document.createElement("i");
    icon.classList.add("bi", "bi-x");

    button.appendChild(icon);

    return button;
  };

  #toggleDisplay() {
    this.displayTarget.classList.toggle("d-none");
  };

  async #askPermission() {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });

    stream.getTracks().forEach((track) => track.stop());
  };

  async #showAvailableDevices(target) {
    await this.#askPermission();

    const list         = target.querySelector("ul");
    const devices      = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(device => device.kind === "videoinput");

    if (videoDevices.length === 1) {
      this.deviceValue = videoDevices[0].deviceId;
    } else {
      target.classList.remove("d-none");
    };

    videoDevices.forEach((device) => {
      list.appendChild(this.#deviceButton({ deviceId: device.deviceId, label: device.label }));
    });

    if (!getCookie(this.DEVICE_COOKIE)) document.getElementById("available_devices").click();
  };

  #deviceButton({ deviceId, label }) {
    const button = document.createElement("li");
    button.id = deviceId;
    button.innerText = label;
    button.classList.add("dropdown-item", "cursor-pointer");

    button.addEventListener("click", () => {
      this.deviceValue = deviceId;
    });

    return button;
  };

  async #calibrate() {
    const types = Object.keys(this.#cameraResolution());

    for (let i = types.length - 1; i >= 0; i--) {
      let type       = types[i];
      let dimensions = this.#cameraResolution()[type];

      try {
        await navigator.mediaDevices.getUserMedia({ video: { aspectRatio: 3/4, deviceId: { exact: this.deviceValue }, ...dimensions } });

        return type;
      } catch (error) {
        continue;
      };
    };

    throw new DOMException("There is no valid resolution for the current camera", "ResolutionUnavailableError");
  };

  async #setVideoStream() {
    if ("mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices) {
      const streamQuality = await this.#streamQuality();
      this.videoStream    = await navigator.mediaDevices.getUserMedia({ video: { aspectRatio: 3/4, deviceId: { exact: this.deviceValue }, ...streamQuality } });

      this.displayTarget.srcObject = this.videoStream;

      if (streamQuality == null) {
        this.element.after(this.#incompatibilityMessage());
      };
    };
  };

  #cameraResolution() {
    return {
      custom: null, // no restrictions
      sd: { width: { exact: 360 }, height: { exact: 480 } }, // 640x480
      hd: { width: { exact: 540 }, height: { exact: 720 } }, // 1280x720
      fhd: { width: { exact: 810 }, height: { exact: 1080 } }, // 1920x1080
      qhd: { width: { exact: 1080 }, height: { exact: 1440 } }, // 2560x1440
      uhd: { width: { exact: 1620 }, height: { exact: 2160 } } // 3840x2160
    };
  };

  async #streamQuality() {
    let type;

    if (hasCookie(this.RESOLUTION_COOKIE)) {
      type = getCookie(this.RESOLUTION_COOKIE);
    } else {
      type = await this.#calibrate();

      setCookie(this.RESOLUTION_COOKIE, type);
    };

    return this.#cameraResolution()[type];
  };

  #incompatibilityMessage() {
    const placeholder = document.createElement("div");
    const message     = document.createElement("p");

    message.innerHTML = "Não foi possível utilizar a <strong>proporção 3X4</strong> <abbr title='Use o Google Chrome, Microsoft Edge ou outro navegador baseado em Chromium'><strong>neste navegador</strong></abbr>"
    message.classList.add("text-center", "m-0");
    placeholder.classList.add("alert", "bg-warning", "bg-opacity-25", "border-warning", "mt-1", "d-flex", "justify-content-center", "align-items-center");
    placeholder.append(message);

    return placeholder;
  };
};
