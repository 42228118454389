import { Controller } from "@hotwired/stimulus"
import { currencyMask } from "./../services/currencyMask"

export default class extends Controller {
  // Caracteres maiusculos
  upperCase() {
    const cursorPosition = this.element.selectionStart;
    this.element.value = this.element.value.toUpperCase();
    this.element.setSelectionRange(cursorPosition, cursorPosition);
  }

  // Caracteres minusculos
  lowerCase() {
    this.element.value = this.element.value
      .toLowerCase()
  }

  // Aceita apenas número
  maskNumber() {
    this.element.value = this.element.value
      .replace(/\D/, "")
  }

  // ${precision - scale},${scale}
  maskDecimal() {
    const precision = this.element.dataset.formatPrecision
    const scale     = this.element.dataset.formatScale

    if (precision && scale) {
      const regex = new RegExp(`^\\d{0,${precision - scale}}(?:\\.\\d{0,${scale}})?$`)

      if (!regex.test(this.element.value)) this.element.value = this.element.value.slice(0, -1)
    }
  }

  // 00000000-0
  maskDoCode() {
    this.element.value = this.element.value
      .replace(/\D/g, "")
      .replace(/(\d{8})(\d{1})/, "$1-$2")
      .replace(/(-\d{1})\d+?$/, "$1")
  }

  // 0000000000-0
  maskDnvCode() {
    this.element.value = this.element.value
      .replace(/\D/g, "")
      .replace(/(\d{10})(\d{1})/, "$1-$2")
      .replace(/(-\d{1})\d+?$/, "$1")
  }

  // 000.000.000-00
  maskDocCpf() {
    this.element.value = this.element.value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1")
  }

  // 00.000.000/0000-00
  maskDocCnpj() {
    this.element.value = this.element.value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1")
  }

  // 000.000.000-00 ou 00.000.000/0000-00
  maskDocCpfCnpj() {
    const onlyNumbers = this.element.value.replace(/\D/g, "")

    if (onlyNumbers.length > 11) {
      this.maskDocCnpj()
    } else {
      this.maskDocCpf()
    }
  }

  // E-mail sem formatacao mas com caracteres minusculos
  maskEmail() {
    this.element.value = this.element.value
      .toLowerCase()
  }

  maskPhone() {
    function standardFormat(value) {
      const onlyNumbers = value.replace(/\D/g, "")

      if (onlyNumbers.length == 8) {
        if (/(\d{4})\-(\d{4})/.test(value.substr(0, 9))) return value

        return onlyNumbers.replace(/(\d{4})(\d{4})/, "$1-$2")
      }

      if (onlyNumbers.length == 10) {
        if (/\((\d{2})\)\s(\d{4})\-(\d{4})/.test(value.substr(0, 14))) return value

        return onlyNumbers.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3")
      }

      if (onlyNumbers.length == 11) {
        const matches = onlyNumbers.matchAll(/(\d{4})(\d{3})(\d{4})/g)

        for (const match of matches) {
          if (match[1] == "0800") {
            if (/(\d{4})\s(\d{3})\s(\d{4})/.test(value.substr(0, 13))) return value

            return onlyNumbers.replace(/(\d{4})(\d{3})(\d{4})/, "$1 $2 $3")
          }
        }

        if (/\((\d{2})\)\s(\d{5})\-(\d{4})/.test(value.substr(0, 15))) return value

        return onlyNumbers.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")
      }

      if (onlyNumbers.length == 12) {
        if (/\+(\d{2})\s\((\d{2})\)\s(\d{4})\-(\d{4})/.test(value.substr(0, 19))) return value

        return onlyNumbers.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, "+$1 ($2) $3-$4")
      }

      if (onlyNumbers.length == 13) {
        if (/\+(\d{2})\s\((\d{2})\)\s(\d{5})\-(\d{4})/.test(value.substr(0, 19))) return value

        return onlyNumbers.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, "+$1 ($2) $3-$4")
      }

      return value
    }

    this.element.value = standardFormat(this.element.value)
  }

  // 00000-000
  maskZipCode() {
    this.element.value = this.element.value
      .replace(/\D/g, "")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{3})\d+?$/, "$1")
  }

  // 00/00/0000
  maskDate() {
    this.element.value = this.element.value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1")
  }

  // 00:00
  maskTime() {
    this.element.value = this.element.value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1:$2")
      .replace(/(\d{2})(\d)/, "$1")
  }

  // R$ 0,00
  maskCurrency() {
    this.element.value = currencyMask(this.element.value)
  }

  // Permite apenas números e barras
  maskOnlyNumbersAndSlashes() {
    this.element.value = this.element.value
      .replace(/[^\d\/]/, "")
  }

  // 00.000-0
  maskCnsCode() {
    this.element.value = this.element.value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1-$2")
      .replace(/(-\d{1})\d+?$/, "$1")
  }

  // 000000.0000.00.00.00000000-00
  maskMneCode() {
    let value = this.element.value.replace(/\D/g, "");

    value = value
      .replace(/(\d{6})(\d)/, "$1.$2")
      .replace(/(\d{6}\.\d{4})(\d)/, "$1.$2")
      .replace(/(\d{6}\.\d{4}\.\d{2})(\d)/, "$1.$2")
      .replace(/(\d{6}\.\d{4}\.\d{2}\.\d{2})(\d)/, "$1.$2")
      .replace(/(\d{6}\.\d{4}\.\d{2}\.\d{2}\.\d{8})(\d{2})/, "$1-$2");
    this.element.value = value;
  }

  // 00000.00000.00000.00000
  maskMnePersonCode(){
    let value = this.element.value.replace(/\D/g, "");

    value = value
      .replace(/(\d{5})(\d)/, "$1.$2")
      .replace(/(\d{5}\.\d{5})(\d)/, "$1.$2")
      .replace(/(\d{5}\.\d{5}\.\d{5})(\d)/, "$1.$2")
      .replace(/(\d{5}\.\d{5}\.\d{5}\.\d{5})(\d)/, "$1.$2");

    this.element.value = value;

  }

  // AAAAAAA-D
  maskCibCode() {
    let value = this.element.value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
  
    let mainPart = value.slice(0, 7);
    let lastChar = value.slice(7, 8);
  
    value = mainPart + (lastChar ? "-" + lastChar : "");
  
    this.element.value = value;
  }
}
